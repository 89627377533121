import { Button, ButtonGroup } from "@chakra-ui/button"
import { FormControl, FormHelperText, FormLabel } from "@chakra-ui/form-control"
import { Box, Center, Divider, Flex, Heading, VStack } from "@chakra-ui/layout"
import { Select } from "@chakra-ui/select"
import { Switch } from "@chakra-ui/switch"
import React, { FC } from "react"
import { FormBox } from "../Form"
import { usePrefs } from "./PrefsContext"

export const PrefsBox: FC = ({ children }) => {
  return (
    <Box w="md">
      <FormBox>
        <VStack spacing={4} align="stretch">
          <Heading>Preferences</Heading>
          {children}
        </VStack>
      </FormBox>
    </Box>
  )
}

export function Prefs() {
  const { prefs, updatePrefs, resetPrefs } = usePrefs()

  return (
    <>
      <FormControl>
        {/* <Json>{prefs}</Json> */}
        <FormLabel>Format</FormLabel>
        <ButtonGroup variant="outline" w="100%">
          <Button
            fontWeight="normal"
            isFullWidth
            mr="-px"
            isActive={prefs.ui === "with-cursor"}
            onClick={() => updatePrefs({ ui: "with-cursor" })}
          >
            With cursor
          </Button>
          <Button
            fontWeight="normal"
            isFullWidth
            mr="-px"
            isActive={prefs.ui === "word-by-word"}
            onClick={() => updatePrefs({ ui: "word-by-word" })}
          >
            Word-by-word
          </Button>
        </ButtonGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Duration</FormLabel>
        <ButtonGroup variant="outline" w="100%">
          <Button
            fontWeight="normal"
            isFullWidth
            mr="-px"
            isActive={prefs.durationSeconds === 15}
            onClick={() => updatePrefs({ durationSeconds: 15 })}
          >
            0:15
          </Button>
          <Button
            fontWeight="normal"
            isFullWidth
            mr="-px"
            isActive={prefs.durationSeconds === 30}
            onClick={() => updatePrefs({ durationSeconds: 30 })}
          >
            0:30
          </Button>
          <Button
            fontWeight="normal"
            isFullWidth
            mr="-px"
            isActive={prefs.durationSeconds === 60}
            onClick={() => updatePrefs({ durationSeconds: 60 })}
          >
            1:00
          </Button>
        </ButtonGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Vocabulary</FormLabel>
        <Select
          value={prefs.vocabSize.toString()}
          onChange={e => updatePrefs({ vocabSize: parseInt(e.target.value) })}
        >
          <option value="5">Top 5 words</option>
          <option value="15">Top 15 words</option>
          <option value="25">Top 25 words</option>
          <option value="50">Top 50 words</option>
          <option value="100">Top 100 words</option>
          <option value="250">Top 250 words</option>
          <option value="500">Top 500 words</option>
          <option value="1000">Top 1000 words</option>
        </Select>
      </FormControl>
      <FormControl>
        <Flex alignItems="center">
          <FormLabel htmlFor="show-errors" mb="0" w="100%">
            Show errors while typing
          </FormLabel>
          <Switch
            id="show-errors"
            isChecked={prefs.showErrorsWhileTyping}
            onChange={e =>
              updatePrefs({ showErrorsWhileTyping: e.target.checked })
            }
          />
        </Flex>
        <FormHelperText>
          Disable to measure your typing speed without getting distracted by
          mistakes.
        </FormHelperText>
      </FormControl>
      {/* {prefs.ui === "with-cursor" && (
        <FormControl>
          <Flex alignItems="center">
            <FormLabel htmlFor="prevent-layout-shift" mb="0" w="100%">
              Prevent layout shift on error
            </FormLabel>
            <Switch id="prevent-layout-shift" />
          </Flex>
          <FormHelperText>
            Don't show mistyped characters, just highlight the current word in
            red
          </FormHelperText>
        </FormControl>
      )} */}
      {/* <FormControl>
            <Flex alignItems="center">
              <FormLabel htmlFor="bounce-back" mb="0" w="100%">
                Bounce back if error
              </FormLabel>
              <Switch id="bounce-back" />
            </Flex>
            <FormHelperText>
              Automatically delete the incorrectly typed word, and the one
              before when you make a mistake.
            </FormHelperText>
          </FormControl> */}
      {/* <FormControl>
            <FormLabel>Lines to show</FormLabel>
            <ButtonGroup variant="outline" w="100%">
              <Button fontWeight="normal" isFullWidth mr="-px">
                2 lines
              </Button>
              <Button fontWeight="normal" isFullWidth mr="-px">
                3 lines
              </Button>
              <Button fontWeight="normal" isFullWidth mr="-px">
                All lines
              </Button>
            </ButtonGroup>
          </FormControl> */}
      {/* {prefs.ui === "with-cursor" && (
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="smooth-cursor" mb="0" w="100%">
            Smooth cursor
          </FormLabel>
          <Switch
            id="smooth-cursor"
            isChecked={prefs.smoothCursor}
            onChange={e => updatePrefs({ smoothCursor: e.target.checked })}
          />
        </FormControl>
      )} */}
      <Divider />
      <Center>
        <Button size="xs" variant="outline" onClick={resetPrefs}>
          ↺ Reset Preferences
        </Button>
      </Center>
    </>
  )
}
