import React from "react"
import { Pulse } from "../Pulse"
import { Timer } from "./Timer"

export function FancyTimer({
  isStarted,
  seconds,
  secondsLeft,
}: {
  isStarted: boolean
  seconds: number
  secondsLeft: number
}) {
  return (
    <Pulse when={isStarted}>
      {secondsLeft <= 3 ? (
        <Timer
          isStarted={isStarted}
          seconds={3}
          secondsLeft={secondsLeft}
          isRed
        />
      ) : (
        <Timer
          isStarted={isStarted}
          seconds={seconds}
          secondsLeft={secondsLeft}
        />
      )}
    </Pulse>
  )
}
