import React, { KeyboardEvent } from "react"
import { randomWords } from "../../wordUtils"
import { isKeyAllowed } from "./isKeyAllowed"
import { usePrefs } from "./PrefsContext"
import { Action } from "./typingReducer"

export function useTypingKeys({
  dispatch,
  startTimer,
}: {
  dispatch: React.Dispatch<Action>
  startTimer?: () => void
}) {
  const { prefs } = usePrefs()

  function handleKeyDown(e: KeyboardEvent<HTMLDivElement>) {
    if (!isKeyAllowed(e.key)) return
    // Prevent scrolling when pressing space
    if (e.key === " ") e.preventDefault()

    const ts = e.timeStamp
    switch (e.key) {
      case "Backspace":
        // CTRL is for Windows OS
        // META is for Mac. CMD backspace normally deletes the whole line, but in this UI (mainly, word-by-word) it should only delete a single word
        if (e.altKey || e.ctrlKey || e.metaKey) {
          dispatch({ type: "DELETE_WORD", ts })
        } else {
          dispatch({ type: "DELETE_CHAR", ts })
        }
        break
      default:
        if (e.key === " ") {
          dispatch({ type: "ADD_SPACE", ts })
        } else {
          // Pressing CMD-R to refresh page, for example, shouldn't cause anything to get typed
          if (!e.altKey && !e.ctrlKey && !e.metaKey) {
            dispatch({ type: "ADD_CHAR", char: e.key, ts })
            startTimer && startTimer()
          }
        }
        break
    }
  }

  function handleReset() {
    dispatch({
      type: "RESET",
      wantedWords: randomWords({ vocabSize: prefs.vocabSize }),
    })
  }

  return { handleKeyDown, handleReset }
}
