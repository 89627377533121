/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import React, { FC } from "react"

export const Pulse: FC<{ when: boolean }> = ({ children, when }) => {
  return (
    <div
      css={css`
        ${when && "animation: pulsate-fwd 500ms ease-in-out both;"}
        @keyframes pulsate-fwd {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
      `}
    >
      {children}
    </div>
  )
}
