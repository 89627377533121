import { Box } from "@chakra-ui/react"
import React, { KeyboardEvent, ReactNode, useState } from "react"
import { TimesUpMessage } from "../typing-test/TimesUpMessage"
import { Boxed } from "./Boxed"
import { BoxedContent } from "./BoxedContent"
import { FocusReminder } from "./FocusReminder"

type Props = {
  children: ReactNode
  onKeyDown: (e: KeyboardEvent<HTMLDivElement>) => void
  isTimeUp: boolean
}
export const BoxedFocus = React.forwardRef<HTMLDivElement, Props>(
  ({ children, onKeyDown, isTimeUp }, ref) => {
    const [focused, setFocused] = useState(false)

    return (
      <Box
        // tabIndex allows div to get focus
        tabIndex={0}
        ref={ref}
        outline="none"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyDown={e => {
          // Prevent scrolling when pressing space
          if (e.key === " ") e.preventDefault()
          if (!isTimeUp) {
            onKeyDown(e)
          }
        }}
      >
        {isTimeUp ? (
          <Boxed>
            <BoxedContent isFaded>{children}</BoxedContent>
            <TimesUpMessage show />
          </Boxed>
        ) : (
          <Boxed focused={focused}>
            <BoxedContent isFaded={!focused}>{children}</BoxedContent>
            <FocusReminder show={!focused} />
          </Boxed>
        )}
      </Box>
    )
  }
)
