import { Box, useColorModeValue } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import React, { CSSProperties, FC } from "react"

const variants = {
  hidden: {
    opacity: 0,
    scale: 1,
    y: 10,
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      delay: 0.15,
      ease: "easeOut",
      duration: 0.2,
    },
  },
  exit: {
    y: 0,
    opacity: 0,
    scale: 1,
    transition: {
      duration: 0,
    },
  },
}

const DelayedFade: FC<{ in: boolean; style: CSSProperties }> = props => (
  <AnimatePresence>
    {props.in && (
      <motion.div
        initial="hidden"
        animate="visible"
        whileHover="hover"
        exit="exit"
        variants={variants}
        style={props.style}
      >
        {props.children}
      </motion.div>
    )}
  </AnimatePresence>
)

export function FocusReminder({ show }: { show: boolean }) {
  const color = useColorModeValue("gray.700", "white")

  return (
    <DelayedFade
      in={show}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <motion.div
        variants={{
          hover: {
            scale: 1.1,
            transition: {
              duration: 0.1,
            },
          },
        }}
      >
        <Box
          fontWeight="semibold"
          fontSize="md"
          color={color}
          pointerEvents="none"
        >
          Click to Focus
        </Box>
      </motion.div>
    </DelayedFade>
  )
}
