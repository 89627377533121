import { Box, Collapse, useColorModeValue, VStack } from "@chakra-ui/react"
import React, { FC } from "react"

export const TimesUpBox: FC<{ show: boolean }> = ({ show, children }) => {
  const borderColor = useColorModeValue("gray.200", "gray.600")

  return (
    // Div to avoid margin collapse that makes transition flaky
    <div>
      <Collapse in={show}>
        <Box
          width="300px"
          border="1px solid"
          borderColor={borderColor}
          borderRadius="2xl"
          px={5}
          pt={4}
          pb={5}
        >
          <VStack spacing={3} align="stretch">
            {children}
          </VStack>
        </Box>
      </Collapse>
    </div>
  )
}
