import {
  Box,
  CloseButton,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react"
import React, { FC, useRef } from "react"
import { UnAuthenticatedAlert } from "../auth/UnAuthenticatedAlert"
import { DisabledWhenLoading } from "../DisabledWhenLoading"
import { Prefs } from "./Prefs"
import { defaultPrefsContextValue, usePrefs } from "./PrefsContext"

export const PrefsPopover: FC = ({ children }) => {
  const closeButtonRef = useRef<any>(null)
  const { updatePrefs } = usePrefs()

  const canUpdate = updatePrefs !== defaultPrefsContextValue.updatePrefs

  return (
    <Popover
      isLazy //isLazy so that rendering this component on each key press doesn't hurt performance
      placement="auto"
      initialFocusRef={closeButtonRef}
      arrowSize={14}
      gutter={14}
      id="prefs-popover"
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent
            rounded="2xl"
            boxShadow="lg"
            _focus={{ boxShadow: "lg", outline: 0 }}
          >
            <PopoverHeader pl={6} py={3}>
              <Flex>
                <Text fontWeight="semibold" fontSize="xl">
                  Preferences
                </Text>
                <Spacer />
                <CloseButton
                  ref={closeButtonRef}
                  onClick={onClose}
                  variant="outline"
                />
              </Flex>
            </PopoverHeader>
            <PopoverArrow />
            {!canUpdate && <UnAuthenticatedAlert />}
            <DisabledWhenLoading isLoading={canUpdate === false}>
              <Box px={6} py={4}>
                <VStack spacing={4} align="stretch">
                  <Prefs />
                </VStack>
              </Box>
            </DisabledWhenLoading>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
