import React, { FC } from "react"

export const BoxedContent: FC<{ isFaded: boolean }> = ({
  children,
  isFaded,
}) => (
  <div
    style={{
      transition: "opacity 200ms ease-out, filter 200ms ease-out",
      opacity: isFaded ? 0.3 : 1,
      filter: isFaded ? "blur(4px)" : "",
    }}
  >
    {children}
  </div>
)
