import { Box, Flex, ScaleFade } from "@chakra-ui/react"
import React from "react"

const variants = {
  exit: {
    opacity: 0,
    scale: 0.5,
    transition: {
      duration: 0,
    },
  },
  enter: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      bounce: 0.5,
      duration: 0.5,
    },
  },
}

export function TimesUpMessage({ show }: { show: boolean }) {
  return (
    <Flex
      position="absolute"
      left="0"
      top="0"
      right="0"
      bottom="0"
      justifyContent="center"
      alignItems="center"
      pointerEvents="none"
    >
      <ScaleFade in={show} variants={variants}>
        <Box
          fontWeight="bold"
          fontSize="2xl"
          backgroundColor="orange.200"
          color="orange.800"
          px={6}
          py={3}
          rounded="full"
          boxShadow="sm"
          // border="2px solid"
          // borderColor="orange.500"
          fontStyle="italic"
        >
          Time's Up!
        </Box>
      </ScaleFade>
    </Flex>
  )
}
