import {
  Button,
  HStack,
  Icon,
  Spacer,
  useToast,
  VStack,
} from "@chakra-ui/react"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import React, { useEffect, useReducer, useRef } from "react"
import { RiSoundModuleLine } from "react-icons/ri"
import { RunData2 } from "../../types"
import { useReplayTimer } from "../../useReplayTimer"
import { randomWords } from "../../wordUtils"
import { useDebug } from "../debug/DebugContext"
import { FancyTimer } from "../typing-test/FancyTimer"
import Stats from "../typing-test/Stats"
import { TimesUpBox } from "../typing-test/TimesUpBox"
import { BoxedFocus } from "./BoxedFocus"
import { usePrefs } from "./PrefsContext"
import { PrefsPopover } from "./PrefsPopover"
import { finalTypedWords, init, typingReducer } from "./typingReducer"
import { useTypingKeys } from "./useTypingKeys"
import { Words } from "./Words"

export function TypingTest2({
  onCompleteRun,
}: {
  onCompleteRun?: (runData: RunData2) => void
}) {
  const { showDebug } = useDebug()
  const mixpanel = useMixpanel()
  const toast = useToast()
  const { prefs } = usePrefs()

  const { durationSeconds, vocabSize } = prefs

  const {
    startTimer,
    resetTimer,
    secondsPassed,
    secondsLeft,
    isTimeUp,
  } = useReplayTimer({ durationSeconds, onTimesUp })

  const inputRef = useRef<HTMLDivElement>(null)
  const [$, dispatch] = useReducer(
    typingReducer,
    init(randomWords({ vocabSize }))
  )
  const { handleReset, handleKeyDown } = useTypingKeys({ dispatch, startTimer })

  useEffect(() => {
    const ref = inputRef.current
    const id = window.setTimeout(() => ref?.focus(), 100)
    return () => window.clearTimeout(id)
  }, [])

  useEffect(() => {
    dispatch({
      type: "RESET",
      wantedWords: randomWords({ vocabSize }),
    })
  }, [vocabSize])

  function reset() {
    resetTimer()
    handleReset()
    setTimeout(() => inputRef.current?.focus(), 0)
  }

  function onTimesUp() {
    if (!onCompleteRun) return

    const typedWords = finalTypedWords($)

    if (showDebug) {
      toast({ title: `Saving... ${typedWords.length} words` })
    }
    try {
      mixpanel.track("run.complete")
      mixpanel.people.increment("run_total_seconds", durationSeconds)
    } catch {}
    onCompleteRun({
      wordsToType: $.wantedWords,
      typedWords,
      history: $.history,
      catalogItemId: `${durationSeconds}_SEC_TOP_${vocabSize}_RANDOM_WORDS`,
      durationSeconds,
    })
  }

  return (
    <VStack spacing={4} align="stretch">
      <BoxedFocus ref={inputRef} isTimeUp={isTimeUp} onKeyDown={handleKeyDown}>
        <Words {...$} />
      </BoxedFocus>
      <HStack spacing={4} justify="start">
        <FancyTimer
          isStarted={secondsPassed > 0}
          seconds={durationSeconds}
          secondsLeft={secondsLeft}
        />
        <Button size="sm" onClick={reset} rounded="full">
          ↺ Reset
        </Button>
        <Spacer />
        <PrefsPopover>
          <Button
            size="sm"
            rounded="full"
            leftIcon={
              <Icon as={RiSoundModuleLine} transform="translateY(-.10em)" />
            }
          >
            Preferences
          </Button>
        </PrefsPopover>
      </HStack>
      <TimesUpBox show={isTimeUp}>
        <Stats
          durationSeconds={durationSeconds}
          wordsToType={$.wantedWords}
          typedWords={finalTypedWords($)}
        />
        <Button colorScheme="blue" onClick={reset} size="lg" boxShadow="md">
          ↺ Go Again
        </Button>
      </TimesUpBox>
    </VStack>
  )
}
